import { Accordion, AccordionDetails, AccordionSummary, Chip, Divider, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import diamond from '../Images/diamond.svg'
import React from "react";

export default function ExperienceCard(){

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    return(
        <Box className = 'card'>
            <Divider
                textAlign="left"
                sx={{
                    "&::before, &::after": {
                    borderColor: "white",
                    },
                }}
                >
                <Typography sx={{fontSize:'24pt', color:'white'}}>Experience</Typography>
            </Divider>
            <Box sx={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', marginTop:'5vh'}}>
                <Box sx={{width:'90%'}}>
                    <Accordion expanded={expanded === 'experience1'} onChange={handleChange('experience1')}
                            sx ={{marginBottom: '1vh', backgroundColor: '#282c34', color:'#a7b2c7'}}>
                        <AccordionSummary
                        expandIcon={< ExpandMoreIcon sx={{color:'#a7b2c7'}}/>}
                        aria-controls="panel1a-content"
                        id="experience-1"
                        >
                        <Box container sx={{width:'100%', flexDirection:'row'}}>
                            <Typography sx={{fontSize:'12pt', fontWeight:'500', float:'left', textAlign:'left', width:'50%'}}>Developer Intern @ FragranceX</Typography>

                            <Box sx={{float:'right', textAlign:'right', width:'50%'}}>
                                <Typography sx={{fontSize:'12pt', fontWeight:'500', marginRight:'1vw'}}>May 2023 - August 2023</Typography>
                            </Box>
                        </Box>
                        
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack>
                                <Box container sx={{width:'100%'}}>
                                    <LocationOnIcon sx={{float:'left'}}/>
                                    <Typography textAlign='left'>
                                        Hauppauge, NY
                                    </Typography>
                                </Box>
                                <Stack sx={{margin:'15px'}}>
                                    
                                    <Typography sx={{textAlign:'left', marginBottom:'10px'}}> 
                                        <img src={diamond} className="diamond" alt='?'/> Working on ecommerce site using the .NET framework 
                                    </Typography>

                                </Stack>

                                <Stack direction='row' spacing={1}>
                                    <Chip label=".NET" color="primary" variant="outlined" sx={{width:'85px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                                    <Chip label="C#" color="primary" variant="outlined" sx={{width:'85px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                                </Stack>

                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    
                    <Accordion expanded={expanded === 'experience2'} onChange={handleChange('experience2')}
                            sx ={{marginBottom: '1vh', backgroundColor: '#282c34', color:'#a7b2c7'}}>
                        <AccordionSummary
                        expandIcon={< ExpandMoreIcon sx={{color:'#a7b2c7'}}/>}
                        aria-controls="panel1a-content"
                        id="experience-1"
                        >
                        <Box container sx={{width:'100%', flexDirection:'row'}}>
                            <Typography sx={{fontSize:'12pt', fontWeight:'500', float:'left', textAlign:'left', width:'50%'}}>Software Engineering Intern @ Clever Devices</Typography>

                            <Box sx={{float:'right', textAlign:'right', width:'50%'}}>
                                <Typography sx={{fontSize:'12pt', fontWeight:'500', marginRight:'1vw'}}>June 2022 - August 2022</Typography>
                            </Box>
                        </Box>
                        
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack>
                                <Box container sx={{width:'100%'}}>
                                    <LocationOnIcon sx={{float:'left'}}/>
                                    <Typography textAlign='left'>
                                        Woodbury, NY
                                    </Typography>
                                </Box>
                                <Stack sx={{margin:'15px'}}>
                                    <Typography sx={{textAlign:'left', marginBottom:'10px'}}> 
                                    <img src={diamond} className="diamond" alt='?'/> Developed a labor intelligence dashboard to provide product managers operational
                                    information to better allocate resources and improve efficiency
                                    </Typography>

                                    <Typography sx={{textAlign:'left', marginBottom:'10px'}}> 
                                    <img src={diamond} className="diamond" alt='?'/> Worked with the data of over 170 engineers that was pulled live from Jira, and changes
                                    on engineer projects and assignments were reflected in Jira
                                    </Typography>

                                    <Typography sx={{textAlign:'left', marginBottom:'10px'}}> 
                                    <img src={diamond} className="diamond" alt='?'/> Designed a system to keep track of engineers and project changes on a Gantt Chart. Would provide
                                     warnings on projects and engineers based off business KPI's
                                    </Typography>

                                    <Typography sx={{textAlign:'left', marginBottom:'10px'}}> 
                                    <img src={diamond} className="diamond" alt='?'/> Researched and found an alternative solution to a legacy plugin that was not forward compatible and 
                                    refactored the code base with it
                                    </Typography>

                                </Stack>

                                <Stack direction='row' spacing={1}>
                                    <Chip label="Typescript" color="primary" variant="outlined" sx={{width:'95px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                                    <Chip label="Angular" color="primary" variant="outlined" sx={{width:'85px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                                    <Chip label="NodeJS" color="primary" variant="outlined" sx={{width:'85px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                                    <Chip label="Express" color="primary" variant="outlined" sx={{width:'85px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                                    <Chip label="Docker" color="primary" variant="outlined" sx={{width:'85px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                                </Stack>

                            </Stack>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'experience3'} onChange={handleChange('experience3')}
                            sx ={{marginBottom: '1vh', backgroundColor: '#282c34', color:'#a7b2c7'}}>
                        <AccordionSummary
                        expandIcon={< ExpandMoreIcon sx={{color:'#a7b2c7'}}/>}
                        aria-controls="panel1a-content"
                        id="experience-1"
                        >
                        <Box container sx={{width:'100%', flexDirection:'row'}}>
                            <Typography sx={{fontSize:'12pt', fontWeight:'500', float:'left', textAlign:'left', width:'50%'}}>Summer Intern @ Nassau BOCES</Typography>

                            <Box sx={{float:'right', textAlign:'right', width:'50%'}}>
                                <Typography sx={{fontSize:'12pt', fontWeight:'500', marginRight:'1vw'}}>June 2018 - August 2018</Typography>
                            </Box>
                        </Box>
                        
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack>
                                <Box container sx={{width:'100%'}}>
                                    <LocationOnIcon sx={{float:'left'}}/>
                                    <Typography textAlign='left'>
                                        Westbury, NY
                                    </Typography>
                                </Box>
                                <Stack sx={{margin:'15px'}}>
                                    <Typography sx={{textAlign:'left', marginBottom:'10px'}}> 
                                    <img src={diamond} className="diamond" alt='?'/> Worked in a team of 6 to improve the school district's technology in PC labs and classrooms
                                    </Typography>

                                    <Typography sx={{textAlign:'left', marginBottom:'10px'}}> 
                                    <img src={diamond} className="diamond" alt='?'/> Installed, maintained, and repaired various hardware and software for the upcoming school year
                                    </Typography>
                                </Stack>

                                <Stack direction='row' spacing={1}>
                                    <Chip label="Communication" color="primary" variant="outlined" sx={{width:'125px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                                    <Chip label="Teamwork" color="primary" variant="outlined" sx={{width:'95px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                                    <Chip label="Time Management" color="primary" variant="outlined" sx={{width:'140', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                                </Stack>

                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Box>
        </Box>
    )
}