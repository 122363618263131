import './App.css';
import NavBar from './Components/Navbar';
import React, { useState, useEffect, useRef } from 'react';
import NET from 'vanta/dist/vanta.net.min';
import WAVE from 'vanta/dist/vanta.waves.min';
import * as THREE from "three";
import AboutCard from './Components/AboutCard';
import { Box } from '@mui/system';
import FadeInSection from './Components/FadeInSection';
import Links from './Components/Links';
import HeroCard from './Components/HeroCard';
import ExperienceCard from './Components/ExperienceCard';
import ProjectsCard from './Components/ProjectsCard';
import FadeIn from 'react-fade-in/lib/FadeIn';
import ContactCard from './Components/ContactCard';

export default function App() {
  const [vantaEffect, setVantaEffect] = useState(0);
  const vantaRef = useRef(null);

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        WAVE({
          el: vantaRef.current,
          THREE: THREE,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.00,
          minWidth: 200.00,
          scale: 1.00,
          scaleMobile: 1.00,
          color: 0x526380
        })
      );
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  return (
    <div className="App" ref={vantaRef}>
      <NavBar/>
        <Box className="cardContainer">
          <div id='hero' className='divider'></div>
          <FadeInSection>
            <HeroCard/>
          </FadeInSection>

          <Box sx={{position:'absolute', width:'100%', alignContent:'center', display:'flex', justifyContent:'center'}} className='fadeIn'>
                {/* <div style={{marginRight:'2vw'}}class='arrow'></div> */}
                <div class='arrow'></div>
                {/* <div style={{marginLeft:'2vw'}}class='arrow'></div> */}
          </Box>

          <div className='divider'></div>
          <div id='about' className='divider'></div>
          <FadeInSection>
            <AboutCard/>
          </FadeInSection>

          <div className='biggerDivider'></div>
          <div id='experience' className='divider'></div>
          <FadeInSection>
            <ExperienceCard/>
          </FadeInSection>

          <div className='biggerDivider'></div>
          <div id='projects' className='divider'></div>
          <FadeInSection>
            <ProjectsCard/>
          </FadeInSection>

          <div className='biggerDivider'></div>
          <div id='contact' className='divider'></div>
          <FadeInSection>
            <ContactCard/>
          </FadeInSection>


          <div className='biggerDivider'></div>

        </Box>
        <Links/>
    </div>
  );
}