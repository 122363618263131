import { Button } from "@mui/material";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box } from "@mui/system";
import { HashLink, HashLink as Link } from 'react-router-hash-link';

const theme = createTheme({
    typography: {
        button: {
          textTransform: 'none'
        }
    }
})

export default function NavBar(props){
    // https://drive.google.com/file/d/15yTn8EjowFzQCNpciEn7CfFOTR-XDHmv/view?usp=sharing

    return(
        <ThemeProvider theme={theme}>
            <Box className="Navbar" sx={{  backgroundColor: '#282c34'}}>
                <Button  sx={{color:'white', marginLeft: '2.5vw', fontSize:'14pt', height:'25px'}}>
                    <Link smooth to="/#hero" style={{ textDecoration: 'none', color:'white' }}>
                            kevinha.dev
                    </Link>
                </Button>

                <Box sx={{width:"30vw", maxWidth:'600px', justifyContent:'space-between', display:'flex', alignItems:'center', marginRight:'2.5vw'}}>
                    <Button sx={{color:'white', height:'25px'}}>
                        <Link smooth to="/#about" style={{ textDecoration: 'none', color:'white' }}>
                            About
                        </Link>
                    </Button>

                    <Button sx={{color:'white', height:'25px'}}>
                        <Link smooth to="/#experience" style={{ textDecoration: 'none', color:'white' }}>
                                Experience
                        </Link>
                    </Button>

                    <Button sx={{color:'white', height:'25px'}}>
                        <Link smooth to="/#projects" style={{ textDecoration: 'none', color:'white' }}>
                                Projects
                        </Link>
                    </Button>

                    <Button sx={{color:'white', height:'25px'}}>
                        <Link smooth to="/#contact" style={{ textDecoration: 'none', color:'white' }}>
                                Contact
                        </Link>
                    </Button>

                    <Button href="https://drive.google.com/file/d/15yTn8EjowFzQCNpciEn7CfFOTR-XDHmv/view?usp=sharing" target="_blank" variant="outlined" sx={{color:'white', borderColor:'white', 
                    "&:hover": {
                        boxShadow:'2px 2px',
                        borderColor:"white"
                    }, height:'35px'}}>
                        Resume
                    </Button>
                </Box>
            </Box>
        </ThemeProvider>
    )
}