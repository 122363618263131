import { Avatar, Divider, Stack, ThemeProvider, Typography, createTheme } from "@mui/material";
import { Box } from "@mui/system";
import Me from '../Images/Me.jpg'
import diamond from '../Images/diamond.svg'

const theme = createTheme({
    typography: {
        
    }
});

export default function AboutCard(){
    return(
        <ThemeProvider theme={theme}>
            <Box className = 'card' sx={{color:'#a7b2c7'}}>
                <Divider
                    textAlign="left"
                    sx={{
                        "&::before, &::after": {
                        borderColor: "white",
                        },
                    }}
                    >
                    <Typography sx={{fontSize:'24pt', color:'white'}}>About</Typography>
                    </Divider>

                <Box sx={{ p: 2, display: 'flex'}}>
                    <Stack sx={{width:'60%'}}>
                        <Typography align='left' sx={{marginBottom:'2vh'}}>
                            Hi, I'm Kevin, a senior studying Computer Science at SUNY Stony Brook with a passion for technology and innovation. 
                            I've known I wanted to be involved in the tech industry ever since I took Robotics my Sophomore year of High School. 
                            I've always enjoyed playing strategy/card games and making cool strategies, Robotics showed me how I can do the same thing with code. 
                        </Typography>
                        <Typography align='left' sx={{marginBottom:'2vh'}}>
                            I've had the opportunity to intern at a company last summer (2022) doing full stack web development using technologies such as Angular, Express.js and Docker.
                            This summer (2023) I have another internship lined up at a different company where I'll be working mostly in the backend with .NET.        
                        </Typography>
                        
                        <Typography align='left' sx={{marginBottom:'2vh'}}>
                            Previously I was a Computer Engineering major before switching to CS and have an in-depth understanding of the interactions between hardware and software. 
                            Since switching to CS has allowed me to take more classes that suit my interests, I've been able to explore and interact with 
                            interesting technologies at a deeper level. 
                        </Typography>
                        
                        <Typography align='left'>
                            Here's some of the technologies I've been working with recently:
                        </Typography>
                        <Box sx={{display:'flex',flexDirection:'row'}}>
                            <ul style={{marginLeft:'2.5vw'}}>
                                <li> <Typography><img src={diamond} className="diamond" alt='?'/> Go</Typography></li>
                                <li> <Typography><img src={diamond} className="diamond" alt='?'/> gRPC</Typography></li>
                                <li> <Typography><img src={diamond} className="diamond" alt='?'/> Protobuf</Typography></li>
                                <li> <Typography><img src={diamond} className="diamond" alt='?'/> TypeScript</Typography></li>
                            </ul>
                            <ul style={{marginLeft:'5vw'}}>
                                <li> <Typography><img src={diamond} className="diamond" alt='?'/> React</Typography></li>
                                <li> <Typography><img src={diamond} className="diamond" alt='?'/> NextJS</Typography></li>
                                <li><Typography><img src={diamond} className="diamond" alt='?'/> Prisma</Typography></li>
                                <li><Typography><img src={diamond} className="diamond" alt='?'/> MongoDB</Typography></li>
                            </ul>
                        </Box>
                    </Stack>
                    <Avatar className='picture' sx={{ width: '40%', height: '40%', marginLeft:'1vw' }} variant="rounded" src={Me}/>
                </Box>
            </Box>

        </ThemeProvider>
    )
}