import { Button, Chip, Divider, Stack, ThemeProvider, Typography, createTheme } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import diamond from '../Images/diamond.svg'


const theme = createTheme({
    typography: {
        button: {
          textTransform: 'none'
        }
    }
})
export default function ProjectsCard(){
    const [project, setProject] = useState(1);

    function handleProjectClick(project){
        setProject(project)
    }

    let content = ""

    if(project === 1){
        content = (
            <Box sx={{width:'75%', position:'relative', color:'#a7b2c7'}}>
                <Typography sx={{fontSize:'20pt', textAlign:'left'}}>
                    MTG Deck Hub (in progress)
                </Typography>
                    <Divider sx={{ backgroundColor:'#a7b2c7' }} />
                    <Stack>     
                        <Typography sx={{textAlign:'left', marginBottom:'10px'}}> 
                        <img src={diamond} className="diamond" alt='?'/> Developed a web application that allows users to make a Magic the Gathering deck and track the card 
                        prices and availability across different vendors
                        </Typography>

                        <Typography sx={{textAlign:'left', marginBottom:'10px'}}> 
                        <img src={diamond} className="diamond" alt='?'/> Go microservice with goQuery used to scrape card data from different vendors and store them in a MongoDB database
                        </Typography>

                        <Typography sx={{textAlign:'left', marginBottom:'10px'}}> 
                        <img src={diamond} className="diamond" alt='?'/> Protobufs and gRPC were used for communication between microservices and the frontend
                        </Typography>
                        
                        <Typography sx={{textAlign:'left', marginBottom:'10px'}}> 
                        <img src={diamond} className="diamond" alt='?'/> NextJS used for data fetching with Prisma, client-side routing and server-side rendering of pages for a fast and seamless user experience
                        </Typography>

                        <Typography sx={{textAlign:'left', marginBottom:'10px'}}> 
                        <img src={diamond} className="diamond" alt='?'/> Various visualization graphics for deck statistics and card prices were implemented using ChartJS
                        </Typography>

                        <Stack direction='row' spacing={1} sx={{ position:'absolute', bottom:'0'}}>
                            <Chip label="Go" color="primary" variant="outlined" sx={{width:'85px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                            <Chip label="Typescript" color="primary" variant="outlined" sx={{width:'95px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                            <Chip label="React" color="primary" variant="outlined" sx={{width:'85px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                            <Chip label="NextJS" color="primary" variant="outlined" sx={{width:'85px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                            <Chip label="Prisma" color="primary" variant="outlined" sx={{width:'85px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                            <Chip label="MongoDB" color="primary" variant="outlined" sx={{width:'95px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                        </Stack>
                    </Stack>
            </Box>            
        )
    }
    /**
     * GeoJSON Editor
     * Change this into a component later
     */
    else if(project === 2){
        content = (
            <Box sx={{width:'75%', position:'relative'}}>
                <Typography sx={{fontSize:'20pt', textAlign:'left', color:'#a7b2c7'}}>
                    GeoJSON Editor (in progress)
                    <Divider sx={{ backgroundColor:'#a7b2c7' }} />
                    <Stack>     
                        <Typography sx={{textAlign:'left', marginBottom:'10px'}}> 
                        <img src={diamond} className="diamond" alt='?'/> Developed a web application that allows users to upload, edit and store their GeoJSON files
                        </Typography>

                        <Typography sx={{textAlign:'left', marginBottom:'10px'}}> 
                        <img src={diamond} className="diamond" alt='?'/> Users could make an account to store their GeoJSON's and publish them to a public repository
                        for other uses to comment on and fork for their own use
                        </Typography>

                        <Typography sx={{textAlign:'left', marginBottom:'10px'}}> 
                        <img src={diamond} className="diamond" alt='?'/> GeoJSON's were displayed with Leaflet and their geometries and properties were manipulated 
                        using low-level Leaflet editing API's
                        </Typography>

                        <Typography sx={{textAlign:'left', marginBottom:'10px'}}> 
                        <img src={diamond} className="diamond" alt='?'/> Live collaborative editing of maps in 'rooms' were implemented using Socket.io 
                        </Typography>

                        <Stack direction='row' spacing={1} sx={{ position:'absolute', bottom:'0'}}>
                            <Chip label="Javascript" color="primary" variant="outlined" sx={{width:'95px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                            <Chip label="React" color="primary" variant="outlined" sx={{width:'85px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                            <Chip label="Redux" color="primary" variant="outlined" sx={{width:'85px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                            <Chip label="MongoDB" color="primary" variant="outlined" sx={{width:'95px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                            <Chip label="Express" color="primary" variant="outlined" sx={{width:'85px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                        </Stack>
                    </Stack>
                </Typography>
            </Box>
        )
    }
    else if(project === 3){
        content = (
            <Box sx={{width:'75%', position:'relative', color:'#a7b2c7'}}>
                <Typography sx={{fontSize:'20pt', textAlign:'left'}}>
                    KV Database (in progress)
                </Typography>
                    <Divider sx={{ backgroundColor:'#a7b2c7' }} />
                    <Stack >     

                        <Typography sx={{textAlign:'left', marginBottom:'10px'}}> 
                        <img src={diamond} className="diamond" alt='?'/> Built a scalable and fault tolerant distributed key value database in Go
                        </Typography>

                        <Typography sx={{textAlign:'left', marginBottom:'10px'}}> 
                        <img src={diamond} className="diamond" alt='?'/> Implemented API layer using gRPC and Protobufs for CRUD operations on the database
                        </Typography>

                        <Stack direction='row' spacing={1} sx={{ position:'absolute', bottom:'0'}}>
                            <Chip label="Go" color="primary" variant="outlined" sx={{width:'85px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                            <Chip label="gRPC" color="primary" variant="outlined" sx={{width:'85px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                            <Chip label="Protobuf" color="primary" variant="outlined" sx={{width:'85px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                        </Stack>
                    </Stack>
            </Box>                        
        )
    }
    else if(project === 4){
        content = (
            <Box sx={{width:'75%', position:'relative', color:'#a7b2c7'}}>
                <Typography sx={{fontSize:'20pt', textAlign:'left'}}>
                    Press Release
                </Typography>
                    <Divider sx={{ backgroundColor:'#a7b2c7' }} />
                    <Stack>
                        <Typography sx={{textAlign:'left', marginBottom:'10px'}}>
                        <img src={diamond} className="diamond" alt='?'/> Wrote a press release for an on campus event called 'Wolfie Tank'
                        </Typography>

                        <Typography sx={{textAlign:'left', marginBottom:'10px'}}>
                        <img src={diamond} className="diamond" alt='?'/> Concisely described the event and its purpose in a way that would be appealing to the public 
                        </Typography>

                        <Typography sx={{textAlign:'left', marginBottom:'10px'}}>
                        <img src={diamond} className="diamond" alt='?'/> Presented the implications and benefits of attending and or participatign in the event to facilitate
                        public interest
                        </Typography>

                        <Typography sx={{textAlign:'left', marginBottom:'10px'}}>
                        <img src={diamond} className="diamond" alt='?'/> Provided needed information on how to attend, quotes from key speakers and links to the appropriate resources
                        </Typography>

                        <Stack direction='row' spacing={1} sx={{ position:'absolute', bottom:'0'}}>
                            <Chip label="Communication" color="primary" variant="outlined" sx={{width:'125px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                            <Chip label="Audience Awareness" color="primary" variant="outlined" sx={{width:'175px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                            <Chip label="Technical Writing" color="primary" variant="outlined" sx={{width:'150px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                        </Stack>

                    </Stack>
            </Box>
        )
    }

    else if(project === 5){
        content = (
            <Box sx={{width:'75%', position:'relative', color:'#a7b2c7'}}>
                <Typography sx={{fontSize:'20pt', textAlign:'left'}}>
                    User Instructions
                    </Typography>

                    <Divider sx={{ backgroundColor:'#a7b2c7' }} />
                    <Stack>
                        <Typography sx={{textAlign:'left', marginBottom:'10px'}}>
                        <img src={diamond} className="diamond" alt='?'/> Wrote user instructions on how to interact with Github using SSH
                        </Typography>

                        <Typography sx={{textAlign:'left', marginBottom:'10px'}}>
                        <img src={diamond} className="diamond" alt='?'/> Explained in simple terms the concepts of ssh key generation and basic linux commands to navigate 
                        through the system and for file management
                        </Typography>

                        <Typography sx={{textAlign:'left', marginBottom:'10px'}}>
                        <img src={diamond} className="diamond" alt='?'/> Provided a step by step guide on how to generate an ssh key and add it to Github and how to install
                        git and use basic commands 
                        </Typography>

                        <Typography sx={{textAlign:'left', marginBottom:'10px'}}>
                        <img src={diamond} className="diamond" alt='?'/> Had intermediate steps to solve common errors that may occur during the process
                        </Typography>


                        <Stack direction='row' spacing={1} sx={{ position:'absolute', bottom:'0'}}>
                            <Chip label="Communication" color="primary" variant="outlined" sx={{width:'125px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                            <Chip label="Audience Awareness" color="primary" variant="outlined" sx={{width:'175px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                            <Chip label="Technical Writing" color="primary" variant="outlined" sx={{width:'150px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                        </Stack>

                    </Stack>
            </Box>
        )
    }

    else if(project === 6){
        content = (
            <Box sx={{width:'75%', position:'relative', color:'#a7b2c7', maxHeight:'600px'}}>
                <Typography sx={{fontSize:'20pt', textAlign:'left'}}>
                    Project Proposal
                    </Typography>

                    <Divider sx={{ backgroundColor:'#a7b2c7' }} />
                    <Stack>
                        <Typography sx={{textAlign:'left', marginBottom:'10px'}}>
                        <img src={diamond} className="diamond" alt='?'/> Worked in a team of 4 to write a project proposal for a new elective course to be offered at Stony Brook University
                        </Typography>

                        <Typography sx={{textAlign:'left', marginBottom:'10px'}}>
                        <img src={diamond} className="diamond" alt='?'/> Researched top tier universities and their course offerings to find a gap in the curriculum that could be filled
                        </Typography>

                        <Typography sx={{textAlign:'left', marginBottom:'10px'}}>
                        <img src={diamond} className="diamond" alt='?'/> Identified stakeholders and their needs and how the course would benefit them
                        </Typography>

                        <Typography sx={{textAlign:'left', marginBottom:'10px'}}>
                        <img src={diamond} className="diamond" alt='?'/> Evaluated feasibility and cost of the course and how it would be implemented
                        </Typography>


                        <Stack direction='row' spacing={1} sx={{ position:'absolute', bottom:'0'}}>
                            <Chip label="Teamwork" color="primary" variant="outlined" sx={{width:'95px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                            <Chip label="Research" color="primary" variant="outlined" sx={{width:'95px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                            <Chip label="Communication" color="primary" variant="outlined" sx={{width:'125px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                            <Chip label="Audience Awareness" color="primary" variant="outlined" sx={{width:'175px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                            <Chip label="Technical Writing" color="primary" variant="outlined" sx={{width:'150px', color:'#a7b2c7', borderColor:'#a7b2c7'}} />
                        </Stack>

                    </Stack>
            </Box>
        )
    }

    return(
        <Box className = 'card'>
            <Divider
                textAlign="left"
                sx={{
                    "&::before, &::after": {
                    borderColor: "white",
                    },
                }}
                >
                <Typography sx={{fontSize:'24pt', color:'white'}}>Projects</Typography>
            </Divider>
            <Box sx={{width:'90%', height:'70%', display:'inline-block', marginTop:'5vh'}}>
                <Box sx={{display:'flex', height:'90%'}}>
                    <Stack sx={{width:'15%', marginRight:'15px'}}> 
                        <ThemeProvider theme={theme}>
                        <Button onClick={()=>{handleProjectClick(1)}} sx={{backgroundColor:'#282c34', color:'#a7b2c7', width:'100%', height:'6vh', fontSize:'12pt', marginBottom:'5px'}}>MTG Deck Hub</Button>
                        <Button onClick={()=>{handleProjectClick(2)}} sx={{backgroundColor:'#282c34', color:'#a7b2c7', width:'100%', height:'6vh', fontSize:'12pt', marginBottom:'5px'}}>GeoJSON Editor</Button>
                        <Button onClick={()=>{handleProjectClick(3)}} sx={{backgroundColor:'#282c34', color:'#a7b2c7', width:'100%', height:'6vh', fontSize:'12pt', marginBottom:'5px'}}>KV Database</Button>
                        <Button onClick={()=>{handleProjectClick(4)}} sx={{backgroundColor:'#282c34', color:'#a7b2c7', width:'100%', height:'6vh', fontSize:'12pt', marginBottom:'5px'}}>Press Release</Button>
                        <Button onClick={()=>{handleProjectClick(5)}} sx={{backgroundColor:'#282c34', color:'#a7b2c7', width:'100%', height:'6vh', fontSize:'12pt', marginBottom:'5px'}}>User Instructions</Button>
                        <Button onClick={()=>{handleProjectClick(6)}} sx={{backgroundColor:'#282c34', color:'#a7b2c7', width:'100%', height:'6vh', fontSize:'12pt', marginBottom:'5px'}}>Project Proposal</Button>
                        </ThemeProvider>
                    </Stack>
                    {content}
                </Box>
            </Box>
        </Box>   
    )
}