import { Stack, ThemeProvider, Typography, createTheme } from "@mui/material";
import { Box } from "@mui/system";
const theme = createTheme({
    typography: {
        fontFamily:'serif'
    },
  })
export default function HeroCard(){
    return(
        <Box container className = 'heroCard'>
            <Box sx={{backgroundColor:'#282c34', width:'550px', height: '300px', borderRadius:'.5vw', 
                marginTop:'-30vh', boxSizing:'border-box', padding:'1vw'}}>
                <ThemeProvider theme={theme}>
                <Typography sx={{ fontSize:'24pt', fontWeight:'600', color:'#647187'}}>
                    Hi, my name is
                </Typography>
                <Typography className='name' sx={{fontSize:'64pt', fontWeight:'900', color:'white'}}>
                    Kevin Ha
                </Typography>
                <Typography sx={{fontSize:'24pt', fontWeight:'900', color:'#647187'}}>
                    An aspiring Full Stack Developer
                </Typography>
                </ThemeProvider>
            </Box>
        </Box>
    )
}