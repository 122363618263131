import { Divider, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';

export default function ContactCard(){
    return(
        <Box className = 'sCard'>
            <Divider
                textAlign="left"
                sx={{
                    "&::before, &::after": {
                    borderColor: "white",
                    },
                }}
                >
                <Typography sx={{fontSize:'24pt', color:'white'}}>Contact</Typography>
            </Divider>        
            <Stack sx={{ width:'100%', color:'#a7b2c7', display:'flex', justifyContent:'center', alignItems:'center' }} spacing={1}>

            <Typography sx={{width:'75%', fontSize:'14pt', marginBottom:'40px'}}> If you have any questions or would like to connect with me, feel free to reach out. I'm currently actively looking for full 
                 time roles and or internship/co-op positions in the field of Software Engineering
            </Typography>

            <Typography>
                Kevin Ha
            </Typography>

            <Typography>
            <LocationOnIcon sx={{float:'left', marginRight:'10px'}}/> 1016 Merillon Avenue, Westbury, NY
            </Typography>

            <Typography>
                <EmailIcon sx={{float:'left', marginRight:'10px'}}/> kevin.c.ha@stonybrook.edu
            </Typography>
            <Typography>
                <EmailIcon sx={{float:'left', marginRight:'10px'}}/> kevinha159@gmail.com
            </Typography>

            <Typography>
                <LocalPhoneIcon sx={{float:'left', marginRight:'10px'}}/> 516-456-1296
            </Typography>

            </Stack>
        </Box>
    )
}