import { Stack } from "@mui/system";
import LinkedIn from '../Images/LinkedIn.png'
import GitHub from '../Images/GitHub.png'
import Email from '../Images/Email.png'
import Line from '../Images/Line.png'

export default function Links(){

    function sendEmail() {
        window.open('mailto:kevinha159@gmail.com');
        console.log('Stuff')
    }

    return (
        <Stack className='links'>
            <a target="_blank" rel="noopener noreferrer" href='https://www.linkedin.com/in/kevin-ha-5916638a/' className ='portrait'>
                <img className='image' src={LinkedIn} alt="?"></img>
            </a>
            <a target="_blank" rel="noopener noreferrer" href='https://github.com/kevinha159' className ='portrait'>
                <img className='image' src={GitHub} alt="?"></img>
            </a>
            <div className='portrait' onClick={sendEmail}>
                <img className='image' src={Email} alt="?"></img>
            </div>
            <img className='line' src={Line} alt="?"></img>
        </Stack>
    )
}